<template>
  <div :class="classes('ml-auto flex flex-row justify-end gap-2', $attrs.class as string)">
    <button
      class="transform rounded-full text-black disabled:opacity-50"
      :disabled="!canMoveLeft"
      @click="emit('prev')"
    >
      <svg
        :class="
          classes(
            size === 'small' && 'h-8 w-8',
            (!size || size === 'medium') && 'h-12 w-12',
            size === 'large' && 'h-12 w-12'
          )
        "
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M15 19l-7-7 7-7"
        ></path>
      </svg>
    </button>
    <button
      :disabled="!canMoveRight"
      class="transform rounded-full text-black disabled:opacity-50"
      @click="emit('next')"
    >
      <svg
        :class="
          classes(
            size === 'small' && 'h-8 w-8',
            (!size || size === 'medium') && 'h-12 w-12',
            size === 'large' && 'h-12 w-12'
          )
        "
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M9 5l7 7-7 7"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  canMoveLeft: boolean;
  canMoveRight: boolean;
  size?: 'small' | 'medium' | 'large';
}
defineProps<Props>();

const emit = defineEmits(['prev', 'next']);
</script>
